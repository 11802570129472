import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Mulish', sans-serif !important;
  }
  @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap');
  body {
    background-color: #000000 !important;
  }
    img {
      height: auto;
      max-width: 100%;
    }
    .fa, .far, .fas {
      font-family: "Font Awesome 5 Free" !important;
    }
    .fab {
      font-family: "Font Awesome 5 Brands" !important;
    }
  .dnone{
  display:none;
  }
  nav{
    background: transparent !important;
    width: 90% !important;
    max-width: 1200px;
    margin: 0 auto !important;
    position: relative !important;
    top: 25px !important;
  }
  #swap-currency-input div, #swap-currency-input input, #swap-currency-input input::placeholder {
    color: #fff !important;
    opacity: 1 !important;
}
#swap-currency-input button svg{
  fill: #f18b13 !important;
}
#swap-currency-output div, #swap-currency-output input, #swap-currency-output input::placeholder {
    color: #fff !important;
    opacity: 1 !important;
}
#swap-currency-output button svg{
  fill: #f18b13 !important;
}
div:before{
  background: #f8931e !important;
}
#join-pool-button:hover{
  opacity: 1 !important;
  background: #f8931e !important;
}
.setting_title h2 {
  color:#f18b13;
}
.pancake:nth-child(2){
  dispaly:none !important;
  visibility: hidden;
}
.pancake:nth-child(3){
  dispaly:none !important;
  visibility: hidden;
}
.butter {
  dispaly:none !important;
  visibility: hidden;
}
.pancake:nth-child(1) {
	background: #f18b13;
	box-shadow: 0 1.5px 0 1.5px #8a8daf ;
}
.connect_wallet_heading{
  color: #f18b13;
}
.hover_button:hover.hover_button div{
  color: #fff !important;
}
.headong h2{
 color:#f18b13
}
.open-currency-select-button:hover.open-currency-select-button div {
  color:#fff !important;
}
#add-liquidity-input-tokena div, #add-liquidity-input-tokenb div {
color:#f18b13;
}
#token-search-input{
  color:#f18b13;
}
.select_ic h2 {
  color:#f18b13;
}
.light_card_content div {
  color:#f18b13;
}
.bottom_conrtnet div{
  color:#f18b13;
}
.select_color div {
  color:#fff;
}
.select_color svg {
  fill:#f18b13;
}
.input_new input{
 color:#f18b13;
}
#farms-table > table > tbody > tr > td > div{
color:#000;
}
.star_hidden {
	visibility: hidden;
}
.dog_img img{
 width:80%;
}
.font_light {
	font-weight: lighter !important;
	font-size: 17px;
	line-height: 26px;
}
.clas_img_img img{
	width: 70%;
	margin: 11px auto;
	display: block;
}
h2.text_c {
	text-align: center;
}
.justifua {
	justify-content: center;
}
.rowContent{
  display: flex;
  margin-top: 30px;
}
.rowContent.top50{
  margin-top: 50px;
}
.grid4{
  width: 40%;
  max-width: 452px;
  padding: 0 15px
}
.grid6{
  width: 50%;
  max-width: 678px;
  padding: 0 15px
}
.grid8{
  width: 60%;
  max-width: 700px;
  padding: 0 15px
}
.grid12 {
  width: 100%;
  max-width: 1152px;
  padding: 0 15px
}
.flexRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 22px;
}
.titleBox{
  margin-bottom: 25px;
}
.titleBox h2{
  font-size: 24px;
  margin-bottom: 0px;
}
.whiteBox{
  background: #393838;
  border-radius: 15px;
  padding: 15px;
  width: 100%;
}
.whiteBox.greyBox{
  background-color: #393838;
  border-radius: 50px 50px 50px 50px;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #f18b13;
  padding: 35px 35px 35px 35px;
}
.whiteBox.greyBox .listBox{
  font-weight: 700;
}
.whiteBox.greyBox .listBox h3{
  font-weight: 700;
}
.whiteBox.greyBox .titleBox h2 {
  color: #f18b13;
  font-size: 20px;
  font-weight: 700;
}
.whiteBox.greyBox .purbleRoundBg{
  font-family: "Mulish", Sans-serif;
  font-size: 17px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0px;
  background-color: #f18b13;
  padding: 12px 40px 12px 40px;
  color: #ffffff;
}
.purbleRoundBg {
    background: #d1d6ff;
    color: #f18b13;
    padding: 8px 20px;
    border-radius: 50px;
}
.listBox span{
  color: #fff;
}
.listBox h3{
  color: #f18b13;
  margin: 0;
}
.roadrTreeImg {
    width: 100%;
    border-radius: 15px;
}
.treeContent {
    position: relative;
}
.buttonAbsolute {
    position: absolute;
    top: 85%;
    left: 30px;
    z-index: 1;
}
.greenButton{
  background: #4e9c66;
  color: #ffffff;
  padding: 8px 18px;
  border-radius: 8px;
  transition: .5s;
}
.greenButton:hover{
  background: #f18b13;
}
.grid5{
  width: 100%;
  max-width: 425px;
  padding: 0 15px;
}
.grid2{
  width: 100%;
  max-width: 302px;
  padding: 0 15px;
}
.buttonGroupFlex {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.greenStakeButton button{
  background: #519c67;
}
.buttonGroupFlex button img{
  margin-right: 7px;
}
.whiteBox.tvlBox {
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.whiteBox.tvlBox.tvlBoxFlexStart{
  justify-content: flex-start;
  padding-top: 30px;
}
.tvlBox h3{
  font-size: 24px;
  margin-bottom: 0px;
}
.tvlBox h2{
  font-size: 38px;
  margin: 20px 0;
  color: #f18b13;
}
.tvlBox p{
  font-size: 18px;
  margin-bottom: 0px;
  color: #4e9c66;
}
.farmStakBox{
   padding-top: 30px;
}
.flexColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.farmStakBox h2{
  color: #f18b13;
  font-size: 20px;
  font-weight: 700;
}
.flexFs{
  display: flex;
}
.fsBox {
    width: 50%;
    margin-top: 20px;
}
.fsBox h3{
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  line-height: 53px;
  font-weight: 700;
}
.fsBox h5, .fsBox p{
  color: #969696;
  font-size: 15px;
  font-weight: 700;
  line-height: 45px;
}
.titleImg{
  margin-top: 15px;
}
.abooutPageTitle{
  font-size: 36px;
  margin-bottom: 15px;
  text-align: center;
}
.aboutContentTitle{
  font-size: 24px;
  margin-bottom: 15px;
  margin-top: 30px;
  text-align: center;
}
.aboutButton{
  margin-left: 8px;
}
.aboutButton button{
  background: #e78638;
  color: #ffffff;
  border-color: #e78638;
}
.purpleButton{
  background: #f18b13;
  color: #ffffff;
  padding: 8px 18px;
  border-radius: 8px;
  transition: .5s;
}
.purpleButton:hover{
  background: #4e9c66;
}
.blockButton .purpleButton{
  margin-top: 25px;
  display: block;
  width: 100%;
  text-align: center;
   padding: 12px 18px;
}
.treeDayBox {
  text-align: center;
}
.treeDayBox h3{
  font-size: 20px;
  color: #f18b13;
  margin-bottom:12px;
}
.artTree{
  max-height: 190px;
}
.fsBox{
  position:relative;
}

.earnBox h3 a i span{
  display: none;
}
.grid3{
  width: 32%;
  max-width: 400px;
  padding: 0 15px
}
.earnBox h2{
  font-size: 20px;
  margin: 20px 0;
  color: #4e9c66;
  font-weight: 500;
}
.earnBox h3{
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  margin-bottom: 0px;
}
.earnBox h3 a{
  font-size: 16px;
  background: #f18b13;
  color: #ffffff;
  padding: 8px 15px;
  border-radius:30px;
  display:inline-block;
  transisation:.5s;
}
.earnBox h3 a:hover{
  background: #4e9c66;
}
.tree_row_secton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.referralContent h4 {
  font-size: 16px;
  font-weight: 400;
  background: #4d9c65;
  padding: 14px 10px;
  border-radius: 30px;
  color: #fff;
  margin: 15px 0;
}
.about_content h1{
	font-size: 36px;
	margin-bottom: 22px;
	color: #f18b13;
}
.about_content.referralContent h1{
  text-align: left;
  font-size: 24px;
  color: #f18b13;
  line-height: 36px;
  margin: 15px 0 0;
}
.referralContent p {
  text-align: left;
}
.about_content p {
	color: #f18b13;
	line-height: 26px;
	font-size: 17px;
	font-weight: lighter !important;
}
ul.referralContent_list {
  padding-left: 30px;
  list-style: circle;
}
.referralContent_list li {
  color: #f18b13;
	line-height: 26px;
	font-size: 16px;
  margin-bottom: 10px;
}
.space_dog .logo_home {
	width: 81px;
}
.space_dog h3 {
	text-align: center;
	color: #f18b13;
}
.about_content {
	margin: 42px auto;
  text-align: center;
}
.justifua {
  margin-top: 15px;
}
.grid_11 {
	width: 100%;
	max-width: 460px;
	padding: 0 15px;
}
.cololr_green{
  color:#f8931e !important;
}
.cololr_green svg{
  fill: #f8931e;
}
.bg_white {
	background: #fff;
	border: none;
}
.top_headein_ss {
	text-align: center;
	padding: 19px 0px;
	font-size: 20px;
	color: #f18b13;
}
.clas_img_img {
	background: #fff;
	border-radius: 10px;
	margin: 0 15px;
	margin-top: 26px;
}
.headin_new{
  font-size: 36px;
  margin-bottom: 0;
}
.partnerLogoMain{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.partnerLogo{
  max-width: 80px;
  max-width: 200px;
  margin: 0 30px;
}
.homeHarvestCard {
    position: relative !important;
    margin-bottom: -50px;
    top: -97px !important;
}
.aboutPageBgTop{
  background: url("../images/aboutbg.jpg") no-repeat scroll center bottom;
  background-size: cover;
  min-height: 400px;
  font-size: 0;
  position: relative;
  margin-bottom: 75px;
}
.wriseAboutLogo{
  background: url("../images/aboutLogo.jpg") no-repeat scroll center bottom;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  bottom: -75px;
  left: 50%;
  margin-left: -75px;
  max-width: 100%;
}
.socialFooter i span{
  display: none;
}
.socialFooter{
  padding: 8px 0;
  background: #f18b13;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
}
.socialFooter ul{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}
.socialFooter ul li{
  margin: 7px;
}
.socialFooter ul li a{
  padding: 6px;
  font-size: 20px;
  color: #ffffff;
  transition: .5s;
}
.socialFooter ul li a:hover{
  color: #f7931e;
}
.farm_page_bg {
  background: #000;
}
.farm_page_bg > div:first-child {
  border-bottom: 1px solid #555555;
}

.connect_modal button {
  opacity: 1 !important;
}
.connect_modal button.hover_button:hover div {
  color: #f18b13 !important;
}
.earn_section .grid3 {
	width: 25%;
}
.refer_section_borrn{
  width: 100%;
}
.refer_section_borrn  .input_icon {
	display: flex;
	border: 1px solid #ccc;
	padding: 4px 7px;
	width: 100%;
	border-radius: 5px;
  margin: 13px auto;
}
.refer_section_borrn  .input_icon input {
	width: 100%;
  color: #f18b13;
	border: none;
}
.refer_section_borrn  .input_icon i{
	color: #4e9c66;
  cursor: pointer;
}
.count_section  {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}
.count_section p {
	margin: 7px 0px;
}
.count_section p.color_green {
	color: #4e9c66;
}
.custom_menubutton > div {
  background-color: transparent;
  border: none;
}
.custom_menubutton > div a {
  padding: 4px 4px 0px;
  color: rgb(76, 84, 155);
  font-size: 16px;
  font-weight: 400;
  position: relative;
  height: 42px;
  margin-right: 20px;
  background-color: transparent;
}
.custom_menubutton > div a.active {
  background-color: transparent;
  color: rgb(76, 84, 155);
  font-weight: 600;
}
.custom_menubutton > div a.active::before {
  content: "";
  position: absolute;
  bottom: 0px;
  height: 4px;
  width: 100%;
  background: rgb(78, 157, 102) !important;
  border-radius: 2px 2px 0px 0px;
}
h2#info-overview-title {
  font-size: 64px;
  position: relative;
  margin: 16px 0;
}
.info_search {
  position: absolute;
  top: 6px;
  right: 15px;
}
.info_search input {
  height: 32px;
  font-size: 14px;
}
h1 {
  margin-top: 32px;
}
.breadcrumbs {
  position: absolute;
  top: 114px;
  background: rgb(238, 238, 238);
  width: 100%;
  left: 0;
  margin: 0;
  padding: 10px 0;
  border-bottom: 1px solid pink;
  display: flex;
  justify-content: center;
  align-items: center;
}
.breadcrumbs li{
  margin-right:20px;
}
.breadcrumbs li a{
  font-size: 16px;
  font-weight: 400;
  padding: 4px 4px 0px 4px;
  height: 42px;
}
.breadcrumbs li a:hover {
  color: #f18b13 !important;
  text-decoration: underline;
}
.breadcrumbs li:nth-child(even){
  display: none;
}
.breadcrumbs li div{
  position: relative;
  font-weight: 600;
}
.breadcrumbs li div:before{
  content: "";
  position: absolute;
  bottom: -10px;
  height: 4px;
  width: 100%;
  background-color: #f8931e;
  border-radius: 2px 2px 0 0;
}

.voting_inp:checked {
  background-color: #f18b13;
}
.text_left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.align-center {
  align-items: center;
}
.copied_div {
  position: relative;
}
.copied_txt {
  position: absolute;
      top: 15px;
}
.bannerTop{
  // min-height: 100vh;
  padding: 70px 0;
}
.bannerTop::before{
  content: "";
  position: absolute;
  right: 0;
  top: -113px;
  background: url("../images/img3home1.png") no-repeat scroll right top;
  width: 941px;
  height: 694px;
}
.bannerTop h1{
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 52px;
  color: #f18b13;
}
.bannerTop h1 span{
  color: #653e0e;
  font-size: 28px;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 52px;
  display: block;
}
.bannerTop h6{
  color: var( --e-global-color-18fc4fa );
  font-family: "Mulish", Sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  color: #a0a0a0;
  margin: 25px 0 20px;
}
.bannerAppButton{
  z-index : 1000;
}
.bannerAppButton a{
  margin-right: 20px;
  display: inline-block;
  z-index : 1000;
}
nav a{
  text-decoration: none !important;
}
iframe{
  display: none;
}
.whiteBox.earnBox.whiteShadowBox{
    box-shadow: 0px 0px 54px 0px rgba(129.00000000000003, 129.00000000000003, 224, 0.13);
    padding: 33px 22px 46px 28px;
    margin-bottom: 40px;
}
.whiteBox.earnBox.whiteShadowBox h3{
  font-size: 20px;
  font-weight: 800;
  line-height: 32px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}
.whiteBox.earnBox.whiteShadowBox h4{
  font-size: 17px;
  font-weight: 400;
  color: #ece7e7;
  margin-bottom: 10px;
  line-height: 28px;
}
.whiteBox.earnBox.whiteShadowBox h2{
  font-size: 17px;
  font-weight: 400;
  color: #969696;
  margin-bottom: 10px;
  line-height: 28px;
}

.whiteBox.earnBox.whiteShadowBox h5 {
  color: #f18b13;
  font-size: 17px;
  font-weight: 700;
  line-height: 28px;
}
.whiteBox.earnBox.purpleBox{
  background-color: #f18b13;
  padding: 50px 20px 65px 29px;
  border-radius: 5px 5px 5px 5px;
}
.whiteBox.earnBox.purpleBox h3{
  color: #ffffff;
  font-family: "Mulish", Sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}
.whiteBox.earnBox.purpleBox h2{
  color: #ffffff;
  font-size: 17px;
  font-weight: 400;
}
.whiteBox.earnBox.purpleBox h4{
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
}
.imgLeftAlign {
  margin-bottom: 35px;
}
.sparcoDetail{
  position: relative;
}
.sparcoDetail::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("../images/imgbg1-325x1024.png") no-repeat scroll right top;
  width: 325px;
  height: 1024px;
  margin-top: -30%;
}
.sparcoDetail::after{
  content: "";
  position: absolute;
  right: 0;
  bottom: -220px;
  background: url("../images/imgbg2.png") no-repeat scroll right bottom;
  width: 415px;
  height: 1382px;
  z-index: -1;
}
.container{
  width: 90% !important;
  max-width: 1200px;
}
.hbLeft h2{
  color: #f18b13;
  font-size: 35px;
  font-weight: 700;
  text-transform: capitalize;
  line-height: 52px;
}
.hbLeft p{
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 5px 0px 20px 0px;
}
.homeBanking{
  padding: 50px 0;
}
.sparcoProcess{
  padding: 0px 0 80px;
}
.sparcoProcess h2{
  color: #f18b13;
  font-size: 35px;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 52px;
  text-align: center;
}
.sparcoProcess h6{
  margin: 25px 0px 77px 1px;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #a0a0a0;
}
.cardWhite {
  box-shadow: 0px 0px 56px 0px rgba(128.99999999999997, 128, 224, 0.13);
  margin: 22px 60px 20px 15px;
  padding: 26px 30px 43px 38px;
  width: 85%;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
}
.cardWhite.cardPurple{
  background: #f18b13;
}
.cardNumber{
  background: url("../images/BG-copy.png") no-repeat scroll 0 0;
  width: 117px;
  height: 109px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  font-weight: 700;
  color: #f18b13;
}
.cardWhite h4{
  font-size: 20px;
  color: #fff;
  margin: 15px 0;
}
.cardWhite.cardPurple h4{
  color: #ffffff;
}
.cardWhite p{
  color: #929292;
  font-size: 15px;
  line-height: 28px;
}
.cardWhite.cardPurple p{
  color: #ffffff;
}
.homeNews h2{
  color: #f18b13;
  font-size: 35px;
  font-weight: 900;
  text-transform: capitalize;
  line-height: 52px;
  text-align: center;
}
.homeNews h6{
  margin: 25px 0px 20px 1px;
  font-size: 19px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #a0a0a0;
}
.blogListHome{
  padding-top: 40px;
}
.blogListHome ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.blogListHome ul li{
  border-bottom: 1px solid #1a1818;
  padding-bottom: 18px;
  margin-bottom: 18px;
}
.blogListHome ul li h3{
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 15px;
}
.blogListHome ul li h3 small{
  font-size: 16px;
  color: #c1c1c1;
  margin-top: 10px;
}
.blogListHome ul li p{
  color: #929292;
  line-height: 28px;
  margin-bottom: 18px;
  font-size:14px;
}
.blogListHome ul li a{
  color: #f18b13;
  font-weight: 700;
}
.footerTop{
  background: #f18b13 url("../images/footerbg.png") no-repeat scroll 0 0;
  background-size: 100%;
  padding: 268px 0px 36px 0px;
}
.flexFooter {
  display: flex;
  align-items:flex-end;
}
.gridFooter{
  width: 100%;
}
.gridFooter.widthLarge{
  width: 50%;
}
.gridFooter.WidthSmall{
  width: 50%;
  padding-left: 15px;
  margin-top:20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.gridFooter ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.footerBottom{
  background: #000;
  padding: 30px 0;
}
.footerBottom p{
  max-width: 90%;
  text-align: center;
  margin: 0 auto;
  color: #ffffff;
  line-height: 28px;
  font-size:14px !important;
}
.gridFooter{
  padding: 0 15px;
}

.gridFooter h3{
  color: #FFFFFF;
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 10px;
}
.gridFooter ul li a{
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
  color: #000;
}
.footerLogo{
  margin-bottom: 20px;
}
.gridFooter p{
  color: #ffffff;
  margin: 0;
  line-height: 24px;
}
@media screen and (max-width: 900px) {
  #homepage-hero {
      width: 100%;
      margin-top: 99px;
  }
.partnerLogoMain{
  flex-wrap: wrap;
}
.partnerLogo {
	margin: 7px 9px;
}
.about_content h1 {
	font-size: 30px;
}
.titleBox h2 {
	font-size: 20px;
}
.farmStakBox h2 {
	font-size: 20px;
}
.fsBox h3 {
	font-size: 17px;
}
.tvlBox h3 {
	font-size: 20px;
}
.tvlBox h2 {
	font-size: 30px;
}
.earnBox h2 {
	font-size: 15px;
}
.bMPnRQ .mobile-icon {
	width: 130px;
}
.info_search {
  position: relative;
  top: unset;
  right: unset;
  justify-content: center;
  display: flex;
}
.nav_wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.info_search {
  margin: 5px 10px 10px;
}
}

@media screen and (max-width: 780px) {
  .homeHarvestCard {
    top: -34px !important;
}
  .rowContent {
    flex-wrap:wrap;
  }
  .grid3 {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .grid8 {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .grid4 {
    width: 100%;
    max-width: 100%;
    padding: 0 15px;
  }
  .roadrTreeImg {
    margin-top: 50px;
  }
  .rowContent > div{
    margin: 14px 0px;
  }
  .bMPnRQ .mobile-icon {
    width: 134px;
  }
  .clas_img_img img {
    width: 46%;
  }
  .about_content h1 {
    font-size: 29px;
  }
  .fsBox:first-child::before {
    right: 6px;
    display:none;
  }
  .flexFooter{
    flex-direction: column;
  }
  .footerTop{
    padding-top: 60px;
  }
  .gridFooter.widthLarge{
    width: 100%;
  }
  .gridFooter.WidthSmall{
    padding-left: 0;
    width: 50%;
    margin-top: 30px;
    text-align:right !important;
  }
  .gridFooter{
    width: 100%;
    padding: 0;
    margin-top: 30px;
  }
}
.list_inline_footer
{
  display:inline-block !important;
  padding-left:0px !important;
}

.list_inline_footer li
{
  display:inline !important;
  margin-left:20px !important;
  color:#000 !important;
}

a:hover
{
  color:#fff !important;
}
.btn_multiplier
{
  height:25px !important;
}
.swap_bg
{
  background:#000 !important;
}
.btn_orange
{
  background-color: #f18b13 !important;
    color: white !important;
}
@media only screen and (max-width:575px)
{
  .headong>div
{
padding-left:5px !important;
padding-right:5px !important;

}
  nav button
  {
    padding: 0 8px !important;
    font-size:14px !important;
  }
  nav
  {
    max-width:100% !important;
    width:100% !important;
  }
  .bannerTop
  {
    padding-bottom:0px !important;
  }
  #homepage-hero
  {
    margin-top:30px !important;
  }
  .whiteBox.earnBox.purpleBox
  {
    margin-bottom:40px !important;
  }
  .whiteBox.earnBox.purpleBox
{
  border-radius:15px !important;
}
.whiteBox.earnBox.whiteShadowBox
{
  margin-bottom:40px !important;
}
.flexRow.titleBox,.whiteBox.greyBox .listBox,.flexFs
{
  display:block !important;
}
.whiteBox.greyBox .titleBox h2
{
  margin-bottom:20px;
}
.whiteBox.greyBox .listBox h3
{
  margin-top:10px !important;
}
.sparcoProcess h2
{
  font-size: 25px !important;
  line-height: 40px !important;
}
.bannerTop h1
{
  font-size: 30px !important;
  line-height: 40px !important;

}
.cardWhite
{
  padding:25px !important;
  margin:0px !important;
  width:100% !important;
  margin-bottom:25px !important;
}
.sparcoProcess h6
{
  margin-botttom:30px !important;
}
.gridFooter.WidthSmall

{
  width:100% !important;
}
}

@media only screen and (min-width:576px) and (max-width:767px)
{
  nav button
  {
    padding: 0 8px !important;
    font-size:14px !important;
  }
  nav
  {
    max-width:100% !important;
    width:100% !important;
  }
  .bannerTop
  {
    padding-bottom:0px !important;
  }
  #homepage-hero
  {
    margin-top:30px !important;
  }
  .whiteBox.earnBox.purpleBox
  {
    margin-bottom:40px !important;
  }
  .whiteBox.earnBox.purpleBox
{
  border-radius:15px !important;
}
.whiteBox.earnBox.whiteShadowBox
{
  margin-bottom:40px !important;
}
.flexRow.titleBox,.whiteBox.greyBox .listBox,.flexFs
{
  display:block !important;
}
.whiteBox.greyBox .titleBox h2
{
  margin-bottom:20px;
}
.whiteBox.greyBox .listBox h3
{
  margin-top:10px !important;
}
.sparcoProcess h2
{
  font-size: 25px !important;
  line-height: 40px !important;
}
.bannerTop h1
{
  font-size: 30px !important;
  line-height: 40px !important;

}
.cardWhite
{
  padding:25px !important;
  margin:0px !important;
  width:100% !important;
  margin-bottom:25px !important;
}
.sparcoProcess h6
{
  margin-botttom:30px !important;
}
.gridFooter.WidthSmall

{
  width:100% !important;
}
}

@media only screen and (min-width:768px) and (max-width:991px)
{
  .whiteBox.greyBox
  {
    padding:20px !important;
  }
  .cardWhite
  {
    width:100% !important;
  }
  .gridFooter
  {
    margin-top:65px !important;
  }
  #homepage-hero
  {
    margin-top:0px !important;
  }
  .bannerTop
  {
    padding-bottom:0px !important;
  }

}


#finished-farms-button:hover,#btn_hover:hover,#finished-pools-button:hover
{
  color:#000 !important;
}
// button
// {
//   color: #393838 !important; 
// }

// button:hover
// {
//   color: #fff !important; 
// }

.socialFooter
{
  display:none !important;
}



`
export default GlobalStyle
